<template>
    <div class="deck-page">
        <div class="deck-container">
            
            <div class="left-area">
                
                <div class="deck-area">
                    <image-deck
                        @deleted="deckImageDeleted"
                        :images="deckImages"
                        ref="imageDeck"
                    />
                </div>
            
            </div>
            
            <div class="goods-area">
                
                <div>
                    <div class="top-buttons">
                        <button @click="goodsStockActive = true" class="btn btn-mr" type="button">商品库</button>
                        <button @click="goodsCollectActive = true " class="btn btn-mr" type="button">收藏夹</button>
                        <button v-if="appId" @click="goodsCustomActive = true " class="btn btn-mr" type="button">
                            自选库
                        </button>
                        <template v-if="(!outfit) || (outfit.type === 'shop')">
                            <button @click="goodsShopActive = true " class="btn " type="button">
                                
                                <template v-if="app.open_store === 1">
                                    <span>小商店</span>
                                </template>
                                <template v-if="app.open_store === 2">
                                    <span>有赞商城</span>
                                </template>
                                <template v-if="app.open_store === 3">
                                    <span>主理人商城</span>
                                </template>
                            
                            
                            </button>
                        </template>
                    </div>
                    
                    <template v-if="loadingOutfit">
                        <img width="48" src="/assets/images/loading.gif" alt=""/>
                    </template>
                    <template v-else-if="selectedGoods.length === 0">
                        <img width="280" src="/assets/images/goods-empty.png" alt="">
                    </template>
                    <template v-else>
                        <perfect-scrollbar :options="{suppressScrollY:false}" class="goods-scroll">
                            <div class="goods-list">
                                
                                <div @click="top(goods)" class="goods-block" v-for="(goods,key) in selectedGoods" :key="goods.id"
                                :class="{'used' : usedGoods.indexOf(goods) >= 0,'third' : (key + 1)%3 === 0 }">
                                        
                                        <button @click.stop="deleteGoods(goods)" class="del-btn"><span
                                            class="iconfont pc-guanbi"></span></button>
                                        
                                        <goods-item disable :goods="goods"/>
                                </div>
                                
                                <div></div>
                                <div></div>
                                <div></div>
                            
                            </div>
                        </perfect-scrollbar>
                    </template>
                </div>
                
                <div class="next">
                    <button @click="next" class="btn">下一步</button>
                </div>
            
            </div>
        
        </div>
        <div class="prices-container">
            <div class="prices">
                
                <span>总价：</span>
                <span class="price">
                        <span class="unit">￥</span>{{ totalPrice }}</span>
                <span>总佣金：</span>
                <span class="comm">
                        <span class="unit">￥</span>{{ totalComm }}</span>
            
            
            </div>
        </div>
        <drawer v-model="drawerActive">
            <deck-content :outfit="outfit" title-image="/assets/images/title.fn.png" @submitContent="deckSave"/>
        </drawer>
        
        <drawer width="720px" v-model="goodsStockActive">
            <goods-stock :selected-list="selectedGoods" type="goods" @selected="goodsSelected"/>
        </drawer>
        
        <drawer width="720px" v-model="goodsCollectActive">
            <goods-stock :selected-list="selectedGoods" type="collect" @selected="goodsSelected"/>
        </drawer>
        
        <drawer width="720px" v-model="goodsShopActive">
            <goods-stock :selected-list="selectedGoods" type="shop" @selected="goodsSelected"/>
        </drawer>
        
        <drawer width="720px" v-model="goodsCustomActive">
            <goods-stock :selected-list="selectedGoods" type="custom-goods" @selected="goodsSelected"/>
        </drawer>
        
        <scheme-qrcode :type="schemeType" :scheme-id=" detailId " v-model="showDetailQrcode"></scheme-qrcode>
    
    </div>
</template>

<script>
import ImageDeck from "./ImageDeck";
import GoodsItem from "@/components/GoodsItem";
import Drawer from "@/components/Drawer";
import DeckContent from "./DeckContent";
import GoodsStock from "./GoodsStock";
import api from "@/repo/api";
import auth from "@/auth";
import SchemeQrcode from "@/components/SchemeQrcode";


export default {
    name: "Home",
    components: {SchemeQrcode, GoodsStock, DeckContent, Drawer, GoodsItem, ImageDeck},
    props: {
        app: Object
    },
    data() {
        return {
            hideMenu: true,
            showSchemeModal: true,
            selectedGoods: [],
            usedGoods: [],
            deckImages: [],
            imageDeck: null,
            drawerActive: false,
            goodsStockActive: false,
            goodsShopActive: false,
            goodsCollectActive: false,
            goodsCustomActive: false,
            outfit: null,
            goods: [],
            loadingOutfit: false,
            totalPrice: '0.00',
            totalComm: '0.00',
            appId: auth.appId(),
            detailId: 0,
            schemeType: 'bang',
            showDetailQrcode: false,
            shopGoodsTypes: [
                'wechat',
                'yc-shop',
            ]
        }
    },
    watch: {
        '$route.query'() {
            this.showDetailQrcode = false
            this.getOutfit()
        },
        usedGoods() {
            let prices = 0;
            let comm = 0
            
            this.usedGoods.forEach(item => {
                
                prices += parseFloat(item.lowest_price || (item.min_price / 100).toFixed(2))
                comm += parseFloat(item.commission || 0.00)
                
            })
            
            this.totalPrice = prices.toFixed(2)
            this.totalComm = comm.toFixed(2)
        },
    },
    mounted() {
        
        this.imageDeck = this.$refs['imageDeck']
        
        this.getOutfit()
        this.getGoods()
        
    },
    methods: {
        showSchemeDetail(id) {
            
            
            console.log(id);
            
        },
        getGoods() {
            let id = this.$route.query.goods
            
            if (!id) return;
            
            api.get('/goods/ids', {ids: id}, (data) => {
                
                if (data.code === 0 && data.data.data.length) {
                    this.selectedGoods = data.data.data
                    this.usedGoods = data.data.data
                    this.reloadGoodsImage()
                }
                
            });
            
        },
        loadOutfit(data) {
            
            this.outfit = data
            this.selectedGoods = this.outfit.goods
            this.usedGoods = this.outfit.goods
            
            if (this.outfit.frame && this.outfit.frame.frame) {
                
                let list = []
                
                let bl = 600 / this.outfit.frame.frame.width;
                
                let zh = (v) => {
                    return bl * v;
                }
                
                this.outfit.frame.frame.goods.forEach(item => {
                    
                    let image = '';
                    
                    this.usedGoods.forEach(goods => {
                        if (goods.id === item.id) image = goods.white_image || goods.thumb
                    })
                    
                    if (!image) return;
                    
                    list.unshift({
                        type: 'image',
                        image: image,
                        style: {
                            left: zh(item.x) + 'px',
                            top: zh(item.y) + 'px',
                            width: zh(item.w) + 'px',
                            height: zh(item.h) + 'px',
                        },
                        active: false,
                    })
                    
                })
                
                this.$refs['imageDeck'].setImageData(list)
                
            } else {
                this.reloadGoodsImage()
            }
            
        },
        getOutfit() {
            
            let id = this.$route.query.outfit_id
            let type = this.$route.query.type || 'bang'
            
            if (!id && !this.outfit) return
            
            if (!id && this.outfit) {
                return location.reload()
            }
            if (id && this.outfit && this.outfit.id !== id) {
                return location.reload()
            }
            
            this.loadingOutfit = true
            
            if (type === 'wechat') {
                
                api.get('/ast-app/scheme-detail', {id}, (data) => {
                    
                    this.loadingOutfit = false
                    
                    if (data.code !== 0) return
                    
                    data.data.goods.forEach(goods => {
                        
                        if ((this.shopGoodsTypes.indexOf(goods.from) === -1) || (!goods.color)) return;
                        
                        goods.title = goods.product_name + ' - ' + goods.color
                        goods.id = goods.id + '-' + goods.color
                        
                    })
                    
                    
                    this.loadOutfit(data.data)
                })
                
            } else {
                
                api.getOutfitDetail(id, (data) => {
                    
                    this.loadingOutfit = false
                    
                    if (data.code !== 0) return
                    
                    this.loadOutfit(data.data)
                })
            }
            
        },
        reloadGoodsImage() {
            
            let images = []
            
            this.usedGoods.forEach(item => {
                
                if (!item.white_image && !item.thumb && !item.thumb_image) return
                
                images.push(item.white_image || item.thumb || item.thumb_image)
            })
            
            this.deckImages = images
        },
        goodsSelected(goods) {
            
            this.goodsCollectActive = false
            this.goodsStockActive = false
            this.goodsShopActive = false
            this.goodsCustomActive = false
            
            if (!goods || !goods.length) return
            
            let unused = this.cj(this.selectedGoods, this.usedGoods)
            
            this.usedGoods = this.cj(unused, goods)
            
            this.selectedGoods = goods
            
            this.reloadGoodsImage()
        },
        
        cj(old, newer) {
            
            let arr = []
            
            if (!newer.length) return [].concat(old)
            
            if (!old.length) return [].concat(newer)
            
            newer.forEach(nitem => {
                
                let find = false
                
                old.forEach(oitem => {
                    
                    if (oitem.id === nitem.id) find = true
                })
                
                if (!find) arr.push(nitem)
            })
            
            old.forEach(nitem => {
                
                let find = false
                
                newer.forEach(oitem => {
                    
                    if (oitem.id === nitem.id) find = true
                })
                
                if (!find) arr.push(nitem)
            })
            
            
            return arr
        },
        
        deckImageDeleted(image) {
            
            let idx = this.deckImages.indexOf(image)
            if (idx < 0) return
            
            this.deckImages.splice(idx, 1)
            
            idx = -1;
            this.usedGoods.forEach((item, key) => {
                if (item.white_image !== image) return
                idx = key
            })
            
            if (idx < 0) return
            this.usedGoods.splice(idx, 1)
        },
        deleteGoods(item) {
            
            let idx = this.selectedGoods.indexOf(item)
            
            this.selectedGoods.splice(idx, 1)
            
            idx = this.usedGoods.indexOf(item)
            if (idx >= 0) this.usedGoods.splice(idx, 1)
            
            this.reloadGoodsImage()
        },
        top(item) {
            
            if (this.usedGoods.indexOf(item) === -1) {
                
                this.usedGoods.push(item);
                this.reloadGoodsImage();
                
                return
            }
            
            this.imageDeck.active(item.white_image)
        },
        next() {
            
            if (!this.usedGoods.length) {
                
                this.$swal({
                    title: '请先完成拼图',
                    icon: 'error',
                    confirmButtonText: '好'
                });
                return
            }
            
            this.drawerActive = true
        },
        
        deckSave(data) {
            this.drawerActive = false
            
            this.$swal.fire({
                title: '提交中，请稍候...',
                allowEscapeKey: false,
                allowOutsideClick: false,
                showConfirmButton: false,
            })
            
            if (this.outfit) {
                data['id'] = this.outfit.id
                data['type'] = this.outfit.type
            }
            
            this.imageDeck.build((img, buildList) => {
                
                data['image'] = img
                
                let goods = []
                let astGoods = []
                let frames = {
                    "width": "600",
                    "height": "600",
                }
                let frameGoods = []
                
                buildList.forEach((bitem) => {
                    this.usedGoods.forEach(item => {
                        
                        if (
                            bitem.image !== item.white_image &&
                            bitem.image !== item.thumb
                        ) return
                        
                        goods.push(item.id)
                        
                        let type = 'bang'
                        let iid = [];
                        
                        
                        if (typeof item.id === 'string') {
                            iid = item.id.split('-')
                        } else {
                            iid = [item.id, '']
                        }
                        
                        
                        if (this.shopGoodsTypes.indexOf(item.from) >= 0) {
                            type = 'shop'
                        }
                        
                        astGoods.push({
                            id: iid[0],
                            type,
                            color: iid[1] || '',
                            from: item.from
                        })
                        
                        frameGoods.unshift({
                            x: parseInt(bitem.style.left),
                            y: parseInt(bitem.style.top),
                            w: parseInt(bitem.style.width),
                            h: parseInt(bitem.style.height),
                            id: item.id,
                            price: item.lowest_price,
                        })
                    })
                    
                })
                
                frames['goods'] = frameGoods
                data['frame'] = frames
                data['type'] = 'shop'
                
                if (this.appId) {
                    
                    data['goods'] = astGoods
                    
                    api.post('/ast-app/create-scheme', data, (result) => {
                        
                        this.$swal.close();
                        
                        if (result.code !== 0) {
                            
                            this.$swal({
                                title: result.msg,
                                icon: 'error',
                                confirmButtonText: '好'
                            });
                            
                            return
                        }
                        
                        if (!this.appId) {
                            return this.$router.push('outfits')
                        }
                        
                        this.schemeType = result.data.app_id > 0 ? 'wechat' : 'bang'
                        
                        this.detailId = (+result.data.id);
                        this.showDetailQrcode = true
                    })
                    
                    return;
                }
                
                if (!this.appId) {
                    
                    data['goods'] = goods
                    
                    api.createOutfit(data, (result) => {
                        
                        this.$swal.close();
                        
                        
                        if (result.code !== 0) {
                            
                            this.$swal({
                                title: result.msg,
                                icon: 'error',
                                confirmButtonText: '好'
                            });
                            
                            return
                        }
                        
                        if (!this.appId) {
                            return this.$router.push('outfits')
                        }
                        
                        this.detailId = (+result.data.id);
                        this.schemeType = 'bang'
                        this.showDetailQrcode = true
                    })
                }
                
                
            })
        }
    }
}
</script>

<style lang="less" scoped>

.deck-area {
    
    background: #F5F5F5;
    
}


.deck-page {
    margin-bottom: 60px;
}

.left-area {
    margin-right: 48px;
    width: 600px;
}

.prices {
    margin-top: 26px;
    text-align: center;
    width: 600px;
    
    span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
    }
    
    .price {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 17px;
        margin-right: 40px;
    }
    
    .comm {
        font-size: 14px;
        font-weight: 400;
        color: #FFAD00;
        line-height: 17px;
    }
    
    .unit {
        font-size: 10px;
    }
}

.deck-container {
    display: flex;
}

.goods-list {
    width: 100%;
    overflow-x: hidden;
}

.goods-scroll {
    height: 528px;
}

.goods-block {
    cursor: pointer;
    height: 260px;
    width: 200px;
    margin-bottom: 36px;
    float: left;
    margin-right: 30px;
    position: relative;
    
    &.third {
        margin-right: 0;
    }
    
    &:hover .del-btn {
        opacity: 1;
    }
}

.del-btn {
    width: 24px;
    height: 24px;
    background-color: white;
    border: 1px solid #EEEEEE;
    border-radius: 50%;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 99;
    font-size: 12px;
    opacity: 0;
    
    span {
        font-size: 12px;
    }
}

.btn-mr {
    margin-right: 16px;
}

.top-buttons {
    margin-bottom: 25px;
}

.used /deep/ .image-container {
    border: 1px solid #333333;
}

.goods-area {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    flex: 1;
}

.deck-container {
    user-select: none;
}

</style>